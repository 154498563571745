import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import LeftBar from "./LeftBar";
import ActionAreaCard from "./Card"
import { CameraIcon } from "@heroicons/react/solid";

const hundredhand = require("../assets/photography/100hand.jpg");
const fireworksafety = require("../assets/photography/fireworksafety.jpg");
const heis = require("../assets/photography/heis.jpg");
const indanger = require("../assets/photography/indanger.jpg");
const memrjones = require("../assets/photography/memrjones.jpg");
const waterwalker = require("../assets/photography/waterwalker.jpg");
const legleg = require("../assets/photography/legleg.png");
const highcouncil = require("../assets/photography/highcouncil.jpg");
const emerge = require("../assets/photography/emerge.jpg");

const Photography = () => {
  return (
    <div
      id="Photography"
      className="mx-12 mt-60 lg:mx-60 items-center justify-center lg:w-1/2 text-justify"
    >
      <div id="home" className="App flex h-max bg-[#1e1e1e]">
        <div style={{position:'fixed',top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
          <div className="lg:hidden">
            <MobileMenu />
          </div>
        </div>
      </div>
      <div>
        <div className="table">
          <CameraIcon className="h-5 w-5 mr-4" style={{color:'#ee5a62'}}/>{" "}
          <code className="table-cell text-[#18849c] text-3xl mt-5 whitespace-nowrap">
          Photography
          </code>
          <div className="table-cell"></div>
        </div>
        <div className="text-[#FFFFFF] text-lg mt-5">
          <code> 
            I have a very active imagination and photography gives me a way to bring my thoughts into the
            real world. I got into it partly to learn photo manipulation as a skill, partly since I needed a
            creative outlet, and (most importantly) partly to have fun. I hope you find something you find 
            cool, weird, confusing, or a bit of each and that you enjoy viewing it a fraction of how much I
            enjoyed making it.
            
          </code>
          <br />
          <br />
          <code style={{color:'#ee5a62'}}>Click a card to find out more about the piece:</code>
          <div style={{zIndex:'-1', position:'absolute'}} className="grid grid-cols-3 mr-12">
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/HundredHand' PageTitle='100 Hand' Content='Inspired by Netero' ImagePath={hundredhand}/>
            </div>
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/MeMrJones' PageTitle='Me & Mr Jones' Content='Any Amy Winehouse Fans?' ImagePath={memrjones}/>
            </div>
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/HeIs' PageTitle='He Is' Content='Furby Hacking Leaks Out' ImagePath={heis}/>
            </div>
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/FireworkSafety' PageTitle='Firework Safety' Content='The Best PSA That Wasn&#39;t' ImagePath={fireworksafety}/>
            </div>
            <div className="flex flex-row  mb-3">
              <ActionAreaCard ToPage='/InDanger' PageTitle='In Danger' Content='Cursed Image Creation' ImagePath={indanger}/>
            </div>
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/WaterWalker' PageTitle='Water Walker' Content='Saturday Afternoon In Style' ImagePath={waterwalker}/>
            </div>
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/LegLeg' PageTitle='Legs Monster' Content='Give me a hand?' ImagePath={legleg}/>
            </div>
            <div className="flex flex-row  mb-3">
              <ActionAreaCard ToPage='/HighCouncil' PageTitle='High Council' Content='How did I get here' ImagePath={highcouncil}/>
            </div>
            <div className="flex flex-row mb-3">
              <ActionAreaCard ToPage='/Emerge' PageTitle='Emerge' Content='The council will decide your fate' ImagePath={emerge}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const PhotographyPage = () => {
  const [showPage] = useState("photographyPage");

  return (
    <div id="photographyPage" className="App flex h-max bg-[#1e1e1e]">
      <div className="bg-[#262526] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div style={{position:'fixed',top:'0', left:'0',}} className="bg-[#1e1e1e] lg:pl-72">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
      <div className="bg-[#1e1e1e] lg:pl-72">
        {showPage==="photographyPage" ? (
          <>
            <Photography id="Photography" />{" "}
          </>
        ) : showPage==="photography" ? (
          <Photography id="Photography" />
        ) : null}
      </div>
    </div>
  );
};


export default PhotographyPage;
