import { BrowserRouter,Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About'
import Resume from './pages/Resume'
import Contact from './pages/Contact'
import Photography from './pages/Photography'
import Photo from './pages/Photo'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}>
        </Route>
        <Route exact path="/About" element={<About/>}>
        </Route>
        <Route exact path="/Resume" element={<Resume/>}>
        </Route>
        <Route exact path="/Contact" element={<Contact/>}>
        </Route>
        <Route exact path="/Photography" element={<Photography/>}>
        </Route>
        <Route exact path="/HundredHand" element={<Photo Ref='HundredHand'/>}>
        </Route>
        <Route exact path="/FireworkSafety" element={<Photo Ref='FireworkSafety'/>}>
        </Route>
        <Route exact path="/HeIs" element={<Photo Ref='HeIs'/>}>
        </Route>
        <Route exact path="/InDanger" element={<Photo Ref='InDanger'/>}>
        </Route>
        <Route exact path="/MeMrJones" element={<Photo Ref='MeMrJones'/>}>
        </Route>
        <Route exact path="/WaterWalker" element={<Photo Ref='WaterWalker'/>}>
        </Route>
        <Route exact path="/LegLeg" element={<Photo Ref='LegLeg'/>}>
        </Route>
        <Route exact path="/Emerge" element={<Photo Ref='Emerge'/>}>
        </Route>
        <Route exact path="/HighCouncil" element={<Photo Ref='HighCouncil'/>}>
        </Route>
      </Routes>  
    </BrowserRouter>

  );
}

export default App;
