import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import LeftBar from "./LeftBar";

const hundredhand = require("../assets/photography/100hand.jpg");
const fireworksafety = require("../assets/photography/fireworksafety.jpg");
const heis = require("../assets/photography/heis.jpg");
const indanger = require("../assets/photography/indanger.jpg");
const memrjones = require("../assets/photography/memrjones.jpg");
const waterwalker = require("../assets/photography/waterwalker.jpg");
const legleg = require("../assets/photography/legleg.png");
const highcouncil = require("../assets/photography/highcouncil.jpg");
const emerge = require("../assets/photography/emerge.jpg");

var hundredhandContent = 'I`ve always been a big fan of television as a way to tell stories, and I have a pretty eclectic taste in most big umbrella entertainment mediums, television being no exception. I got into anime as a child and have stuck with it into adulthood. I love fantasy, action, and storytelling and have found a lot of different shows that exceptionally blend all three. This photo is inspired by the character Isaac Netero in one such show Hunter x Hunter, more specifically his 100 type guanyin bodhisattva power. This power in itself is inspired by Avalokiteshvara the Bodhisattva of Compassion namely the 11 headed thousand armed form they sometimes inhabit. I have always found that image incredibly beautiful and was deep into a viewing of hunter x hunter at the time so since that image was always on my mind I figured I had to make it for real.'
var hundredhandHowTo = 'This can be made up of five individual photos of you sitting on a table with arms at various symmetrical angles, and one photo of a blank background. To achieve the effect cut yourself out from the first five, and overlay all of them on to the background shot to acheive the levitation effect.'
var fireworksafetyContent = 'For this one I honestly just wanted to see if I could make a photo that looked like I had blown myself up. Frankly I really just thought it would be funny and the weekend I was working on it coincided with Canada day so I thought a firework related incident would make for a good story. This is loosely inspired by the time I blew myself up in my bedroom as a kid playing with Armstrong`s mixture (it is what makes the bang in cap guns) but that incident only left me with some ear ringing and charred fingertips.  '
var fireworksafetyHowTo = 'First set up your camera on a tripod in the area you want to take the photo. Then fill a cup with ash from your nearest fire pit, pour water on yourself (it has to stick) and blow hard into the cup, this gives you the coated in ash look. Then grab a bottle of ketchup and use it liberally as fake blood. Once you`re nice and messy take a background shot and another shot of you with your arm outstretched. Take a hot shower, then cut yourself out of the photo (leave the arm behind) and overlay it onto the background shot.'
var heisContent = 'Did you know there is a fairly active community online for Furby hacking? A lot of people have contributed some pretty amazing work on making these little 90`s fluff balls into a variety of useful, albeit unsettling, tools. I got into it a couple months ago and am currently working on a Furby based home automation tool where Furby will be a fully animated talking home assistant capable of automatic coffee brewing, outfit selection, and daily planning. In my research on Furby hacking I ran into another Furby based subculture, Furby modding, where people customize their furbies to give them a super unique look. The Furby used in the photo was titled `Biblically Accurate Furby` and I loved the design so much I just had to use it in a photo. This one is definitely one of my weirder works, but I think that`s part of the reason it`s also one of my favorites.'
var heisHowTo = 'First find a white bed sheet to use as a makeshift toga, if you need a guide on this there are thousands on the internet. Find a nice nature scene near you, and set up your camera for the picture. Take a nice worshiping stance to really sell the importance of the Furby and snap the pic. Then cut out your favorite Biblically Accurate Furby from your source and overlay it onto the photo. Bonus points if you use filters to really emphasize Furby.'
var indangerContent = 'I am a huge fan of surrealism, René Magritte being a personal favorite artist who falls under that category, and as such a lot of my photo ideas also fall in the surrealism camp. This photo in particular is heavily inspired from the idea of a cursed image, images that leave the viewer with an odd or even worried feeling post viewing. For this image I tried to include some of the common themes in cursed images such as randomness, a sense of fear, and oddly enough people in their underwear. I think I did a fairly good job making an image that feels cursed in some way, and am overall very happy with how this all turned out.'
var indangerHowTo = 'Set up your camera on a tripod then take a background shot and a main shot of you running away expressively (super important to sell the fear on your face). Next hop into your favorite pair of underwear and try again to sell that you are a scary monster with your expression, take multiple shots in different locations. When you`re done, cut yourself out from all the photos and overlay them onto the background. '
var memrjonesContent = 'I am a huge fan of Amy Winehouse, I love her voice and musical styling and could go on and on about it. My favorite song from her is `Me & Mr Jones`, I think it showcases her voice beautifully and whenever I listen to it I get these old school black and white club visuals which I love as an aesthetic. That song is the inspiration for this picture and I am playing the roles of both characters. My favorite part of this photo is the subtle effect of me pouring the wine into the glass (zoom in to see it). This was one of my favorite pieces to shoot since I got to dress up twice and I hope I showcased the beauty of both characters well with my portrayal.'
var memrjonesHowTo = 'Set up your tripod. Set up your background and take a picture. Next dress up in your suit and find something to rest a wine glass on (I used a roll of paper towels), take a picture of you holding the wine glass. Next switch out into your dress and find a bottle of wine to pour into the wine glass. Take the picture while you`re pouring so you get the stream of wine entering the glass. Finally cut yourself out of both pictures while keeping both the bottle and glass in their respective cut outs, and finish by overlaying them onto the background.'
var waterwalkerContent = 'This one has a pretty simple explanation, I wanted to show off my new sweater. Now this is not just any sweater, it is a sweater from my favorite skateboard brand Dime and it is extra special since it is a play on the Java logo. Since it blends two of my favorite interests, skateboarding and coding, I just had to have it. I wanted to show off the sweater but I knew I could not just do it in a normal picture (where is the fun in that?), so I decided to make a walking on water effect to make it more interesting for me to make, and others to view. '
var waterwalkerHowTo = 'Take your favorite milk crate down to your local watering hole. Set up your camera and take a background shot, next go into the water and find where you can just barely hide the milk crate under the surface. Stand on the milk crate and take another picture of yourself. Finally cut yourself out of the shot and overlay it onto the background photo.'
var emergeContent = 'I had some photos of my friends and I out at the annual Carleton vs Ottawa football game (known as Panda Game) which I wanted to post. Inspired by a similar photo I saw online of a creepy figure emerging from a picture on the wall, I thought I could make a funny lead in so I would get the opportunity to improve my editing skills while making my friends laugh. Luckily for me, I had messy roommates at the time and they kindly left all the furnishings I needed to stage this photo in what once was our lounging room. If I were to make this again, I would take the time to print out a photo to put in the frame since I think it would make this photo even funnier.'
var emergeHowTo = 'The key ingredients for this photo are a table, tape, and an empty picture frame that you are able to fit inside of. First you`ll want to take apart the picture frame such that the square frame is separate. Afterwards, fit your body into the frame and adjust yourself so that at least some of the frame is able to be put on the table`s surface. When you have that sorted, put tape underneath the frame and stick it to the table to hide it from the camera`s view in order to allow it to hang off the table without extra support. Then all you have to do is slip into the frame to get the photo of you coming out of it, then put the glass and photograph back into the frame to get the background shot. Afterwards you just have to cut yourself out of the first picture and slap it onto the second and you`re all done.'
var leglegContent = 'I am an avid fan of body horror and have had a number of ideas in the past for pictures that would allow me to play in that realm. Most of them haven`t panned out (yet!) as I am still learning and some of the techniques I would need to employ in order to achieve some of my weirder ideas are fairly advanced. Thus in lieu of turning myself into a snake (no, I will not elaborate) I decided to make what you see now, which I have dubbed “Leg Monster”.'
var leglegHowTo = 'In my opinion this is one of the nicer edits I have made but it is very simple to recreate. For my version all I had to do was lie down on my bed both ways, and take a number of pictures with my torso in different positions. I then took some time to look at the photos I had taken, and chose the ones that I thought would best line up. In order to save yourself some time, I would put some sort of marker for where you want your hips to line up. This will remove the need for you to manually pick out the photos that will line up. '
var highcouncilContent = 'Liminal Spaces - the uncertain transition between where you`ve been and where you`re going physically, emotionally, or metaphorically. This was an image I created for the new year of 2024. When one of my former roommates moved out, they left their old room in the state that you see in the photo, balloons and all. The mixture of old hardwood, mismatched chairs, and half-deflated birthday balloons strewn about on the floor just screamed liminal space to me. For some background, I knew that 2024 was going to be a big transitional year for me as it was the year I graduated from university. With any major life transition it comes with some uncertainty as to what the next steps will be and thus a commemorative photograph in a liminal space struck me as a cute metaphor for my position in life at that time. To elaborate on that I decided to fill the chairs with different versions of myself to further play into this idea of uncertainty of the future. Using multiple copies of myself also doubled as making a play on the idea of “new year new me” and so I joked in the image caption with “why stop at making one new you”. Overall this is a favorite photo of mine as it holds personal meaning to me and makes me feel like I am achieving the goals I set out when I bought my camera, to add an avenue for creativity in my life.'
var highcouncilHowTo = 'For this all you really have to do is set up chairs, sit in each one for a photo, and then cut them together. One tip to make the cutting easier would be to leave ample room between chairs so that when you are sitting for the photo you are not covering up another chair`s area in the frame.'

var photoDict = {
    'HundredHand': ['100 Hand', hundredhandContent, hundredhandHowTo, hundredhand],
    'FireworkSafety': ['Firework Safety', fireworksafetyContent, fireworksafetyHowTo, fireworksafety],
    'HeIs': ['He Is', heisContent, heisHowTo, heis],
    'InDanger': ['In Danger', indangerContent, indangerHowTo, indanger],
    'MeMrJones': ['Me & Mr Jones', memrjonesContent, memrjonesHowTo, memrjones],
    'WaterWalker': ['Water Walker', waterwalkerContent, waterwalkerHowTo, waterwalker],
    'LegLeg': ['Legs Monster', leglegContent, leglegHowTo, legleg],
    'Emerge': ['Emerge', emergeContent, emergeHowTo, emerge],
    'HighCouncil': ['High Council', highcouncilContent, highcouncilHowTo, highcouncil],
}

function Photo ({Ref}) {
  return (
    <div id="Photo" className="pb-72 mt-36">
      <div style={{textAlign:'center'}} className="table mx-12 lg:mx-60">
        <code className="table-cell text-[#18849c] text-6xl mt-5">
          {photoDict[Ref][0]}
        </code>
        <div className="table-cell"></div>
      </div>
      <div className="text-[#FFFFFF] text-lg mt-5 mx-12 lg:mx-60 flex flex-col items-center text-justify">
        <img src={photoDict[Ref][3]} alt='PhotoImage' style={{width: '75%', height: '75%'}}/>
        <code style={{marginTop:'5%', fontSize:'20px'}}>
         {photoDict[Ref][1]}
        </code>
        <div style={{textAlign:'center', marginTop:'5%'}} className="table mx-12 lg:mx-60">
        <code className="table-cell text-[#ee5a62] text-2xl mt-5">
          How To
        </code>
        </div>
        <code style={{marginTop:'5%', fontSize:'20px'}}>
         {photoDict[Ref][2]}
        </code>
        <button className=" border border-[#18849c] text-[#ee5a62] mt-10 p-0 rounded hover:bg-opacity-10 hover:bg-lightblue_vs w-1/1">
          <a href="/Photography" style={{display:'block', height:'100%', width:'100%'}}>
            <code>&#160;All Photos&#160;</code>
          </a>
        </button>
      </div>
    </div>
  );
};

export default function PhotoPage({Ref}){
  const [showPage] = useState("photoPage");
  return (
    <div id="photoPage" className="App flex h-max bg-[#1e1e1e]">
      <div className="bg-[#262526] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div style={{position:'fixed',top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
      <div className="bg-[#1e1e1e] lg:pl-72">
        {showPage==="photoPage" ? (
          <>
            <Photo Ref={Ref} id="Photo" />{" "}
          </>
        ) : showPage==="photo" ? (
          <Photo Ref={Ref} id="Photo" />
        ) : null}
      </div>
    </div>
  );
}
