import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import LeftBar from "./LeftBar";
import { IdentificationIcon } from "@heroicons/react/solid";

const PythonLogo = require("../assets/logos/python.png");
const DockerLogo = require("../assets/logos/docker.png");
const GitLogo = require("../assets/logos/git.png");
const GoLogo = require("../assets/logos/gopher.png");
const HaskellLogo = require("../assets/logos/haskell.png");
const K8sLogo = require("../assets/logos/helm.png");
const JavaLogo = require("../assets/logos/java.png");
const RLogo = require("../assets/logos/letter-r.png");
const LinuxLogo = require("../assets/logos/linux.png");
const BASHLogo = require("../assets/logos/sh.png");
const SQLLogo = require("../assets/logos/sql-server.png");
const CPPLogo = require("../assets/logos/c-.png");

const About = () => {
  return (
    <div
      id="About"
      className="mx-12 mt-60 lg:mx-60 items-center justify-center lg:w-1/2 text-justify"
    >
      <div id="home" className="App flex h-max bg-[#1e1e1e]">
        <div style={{position:'fixed', top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
          <div className="lg:hidden">
            <MobileMenu />
          </div>
        </div>
      </div>
      <div>
        <div className="table">
          <IdentificationIcon style={{color:'#ee5a62'}} className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#18849c] text-3xl mt-5 whitespace-nowrap">
            About Me
          </code>
        </div>
        <div className="text-[#FFFFFF] text-lg mt-5">
          <code>
            Hi I'm Jacob Schroeder, thank-you for coming to my corner of the internet! <br/>
            <br/>
            I've been programming for 10 years now and have had a lot of experience with a variety
            of tools and languages (see some of my favourites below). Most of my work experience 
            has been in DevOps and Automation however I am always looking to explore new areas since 
            I love learning new things and developing my existing skills. Aside from my day job,
            my biggest interests lie in fitness, music, pokemon (I battle pokemon competively, it is
            exactly as nerdy as it sounds), and the outdoors. My hobbies are photography (you can see 
            my work in the Photography tab), skateboarding, and making things (a wide umbrella I know, but
            how else do you combine hacking Furbies, wood-working, mechanical projects, and just general
            crafting related tom-foolery into one hobby). <br/>
            <br/>
            That is it from me for now, I plan on updating this website with some videos and blog
            posts in the near future, so check back regularly to see what I'm up to.

          </code>
          <br />
          <br />
          <code style={{color:'#ee5a62'}}>Tools & Languages I Love:</code>
          <div style={{marginBottom:'20%'}} className="grid grid-cols-3 gap-2 text-sm mt-5 text-[#FFFFFF]">
            <div className="flex flex-row  mb-3">
              <img
                src={PythonLogo}
                alt="PythonLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Python</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={DockerLogo}
                alt="DockerLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Docker</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={GitLogo}
                alt="GitLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Git</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={GoLogo}
                alt="GoLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Golang</code>
            </div>
            <div className="flex flex-row  mb-3">
              <img
                src={K8sLogo}
                alt="K8sLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>K8s</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={LinuxLogo}
                alt="LinuxLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Linux</code>
            </div>
            <div className="flex flex-row  mb-3">
              <img
                src={BASHLogo}
                alt="BASHLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>BASH</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={JavaLogo}
                alt="JavaLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Java</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={SQLLogo}
                alt="SQLLogoo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>SQL</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={CPPLogo}
                alt="CPPLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>C++</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={RLogo}
                alt="RLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>R</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={HaskellLogo}
                alt="HaskellLogo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Haskell</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const AboutPage = () => {
  const [showPage] = useState("aboutPage");

  return (
    <div id="aboutPage" className="App flex h-max bg-[#1e1e1e]">
      <div className="bg-[#262526] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div style={{position:'fixed',top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
      <div className="bg-[#1e1e1e] lg:pl-72">
        {showPage==="aboutPage" ? (
          <>
            <About id="About" />{" "}
          </>
        ) : showPage==="about" ? (
          <About id="About" />
        ) : null}
      </div>
    </div>
  );
};


export default AboutPage;
