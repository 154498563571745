import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import LeftBar from "./LeftBar";
import { MailIcon } from "@heroicons/react/solid";

const Contact = () => {
  return (
    <div id="Contact" className="pb-72 mt-36">
      <div className="table mx-12 lg:mx-60">
        <MailIcon style={{color:'#ee5a62'}} className="h-5 w-5 mr-4" />
        <code className="table-cell text-[#18849c] text-3xl mt-5 whitespace-nowrap">
          Get In Touch
        </code>
        <div className="table-cell"></div>
      </div>
      <div className="text-[#FFFFFF] text-lg mt-5 mx-12 lg:mx-60 flex flex-col items-center text-justify">
        <code>
          I'm currently looking for a co-op opportunity for 4-8 months
          starting Winter 2023 <br />
          If you have any questions for me, want to say hi, or want 
          to challenge me to a battle on pokemon showdown (warning I'm really good),
          then feel free to email me by clicking the button below.
        </code>
        <button className=" border border-[#18849c] text-[#ee5a62] mt-10 p-0 rounded hover:bg-opacity-10 hover:bg-lightblue_vs w-1/1">
          <a href="mailto:jacobjschroed@gmail.com">
            <code>&#160;Contact&#160;</code>
          </a>
        </button>
      </div>
    </div>
  );
};

const ContactPage = () => {
  const [showPage] = useState("contactPage");

  return (
    <div id="contactPage" className="App flex h-max bg-[#1e1e1e]">
      <div className="bg-[#262526] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div style={{position:'fixed',top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
      <div className="bg-[#1e1e1e] lg:pl-72">
        {showPage==="contactPage" ? (
          <>
            <Contact id="Contact" />{" "}
          </>
        ) : showPage==="contact" ? (
          <Contact id="Contact" />
        ) : null}
      </div>
    </div>
  );
};

export default ContactPage;
