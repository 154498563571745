import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import LeftBar from "./LeftBar";
import { ClipboardListIcon } from "@heroicons/react/solid";

const Resume = () => {
  return (
    <div
      id="Resume"
      className="lg:w-1/2 mt-64 mb-4 text-justify mx-12 lg:mr-60 lg:ml-60"
    >
      <div>
        <div className="table">
          <ClipboardListIcon style={{color:'#ee5a62'}} className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#18849c] text-3xl mt-5 whitespace-nowrap">
            Resume
          </code>
        </div>
      </div>
      <div className="text-[#a2aabc] text-lg mt-5">
        <div className="flex flex-row">
          <div className="w-1/4">
            <code className="text-[#18849c]">Degree</code>
          </div>
          <div className="w-3/4">
            <code className="text-[#ee5a62]">Carleton University</code>
            <br />
            <code className="italic text-sm text-[#18849c]">
              Bachelors Of Computer Science (Honors), Co-op Option 
            </code>
            <code className="text-xs text-[#FFFFFF]">
              <br />• Graduated with high distinction in 2024 (3.94 GPA)
              <br />• Minors in Business and Statistics
            </code>
          </div>
        </div>
        <div className="flex flex-row pt-10 flex-wrap">
          <div className="w-1/4">
            <code className="text-[#18849c]">Skills</code>
          </div>
          <div className="w-3/4">
            <code className="text-sm text-[#FFFFFF]">
              <br />• Experience in programming using Golang, Python, Java, SQL, Javascript, R, C, C++, and BASH through creating personal and professional projects utilizing these languages
              <br />• Extensive knowledge of data structures, documentation, analysis of algorithms, OOP, APIs, Linux, databases, and test design through self-guided study and application of these skills in my academic career
              <br />• Proficient in the use of the tools Docker, Kubernetes-Rancher, Openstack, Git, Keycloak, Grafana, Prometheus and Jenkins from utilizing these tools in my previous internships and coop placements    
            </code>
          </div>
        </div>
        <div className="flex flex-row pt-10">
          <div className="w-1/4">
            <code className="text-[#18849c]">Work</code>
          </div>
          <div className="w-3/4">
            <code className="text-[#ee5a62]">Software Developer</code>
            <br />
            <code className="italic text-sm text-[#18849c]">
              Blackberry{" "}
            </code>
            <br />
            <code className="text-xs text-[#FFFFFF]">May 2022-August 2022 and January 2023-August 2023</code>
            <br />
            <code className="text-sm text-[#FFFFFF]">
              <br />• Assembled and maintained an automatic code organization pipeline which ensured parity between the locations of our tests in our repository and on Testrail.
              <br />• Programmed a tool which read my team’s testing burn-down chart and populated a custom-built template website with the results, saving my manager time and effort.
              <br />• Utilized my skills in rapidly ramping up on new technologies to become my team’s expert on the Amazon EC2 cloud, documenting its use for our testing teams and creating the necessary support for it to be included as a platform for automated tests. 
              <br />• Employed my knowledge of Docker in order to containerize our testing infrastructure, enabling the rapid set-up and destruction of testing environments. 
            </code>
            <br/><br/>
            <code className="text-[#ee5a62]">Software Engineer</code>
            <br />
            <code className="italic text-sm text-[#18849c]">
              General Dynamics{" "}
            </code>
            <br />
            <code className="text-xs text-[#FFFFFF]">May 2021-December 2021</code>
            <br />
            <code className="text-sm text-[#FFFFFF]">
              <br />• Aided in the design and development of a CLI which automated the deployment of Dev-Ops tools such as Kubernetes-Rancher, Openstack and Keycloak.
              <br />• Developed a Gitlab pipeline job which automatically tagged commits included in a release, removing manual overhead which cost our team valuable time.
              <br />• Created a package which automatically installed Prometheus monitoring as well as custom-made and integrated Grafana dashboards onto a deployed Kubernetes cluster.
            </code>
            <br/><br/>
            <code className="text-[#ee5a62]">Dev-ops Intern</code>
            <br />
            <code className="italic text-sm text-[#18849c]">
              Nokia{" "}
            </code>
            <br />
            <code className="text-xs text-[#FFFFFF]">July 2018-August 2018</code>
            <br />
            <code className="text-sm text-[#FFFFFF]">
              <br />• Designed automated tests for the payment calculation systems in Java using JUnit, ensuring the correct calculation of mobile bills for our industry partners.
              <br />• Applied my knowledge of Docker and Linux to drastically reduce artifact package size, reducing the team’s storage requirements and speeding up our workflow.
              <br />• Helped to plan and facilitate a youth day camp where I taught grade-school children the basics of computer science as well as entrepreneurship skills.
            </code>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResumePage = () => {
  const [showPage] = useState("resumePage");

  return (
    <div id="resumePage" className="App flex h-max bg-[#1e1e1e]">
      <div className="bg-[#262526] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div style={{position:'fixed',top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
      <div className="bg-[#1e1e1e] lg:pl-72">
        {showPage==="resumePage" ? (
          <>
            <Resume id="Resume" />{" "}
          </>
        ) : showPage==="resume" ? (
          <Resume id="Resume" />
        ) : null}
      </div>
    </div>
  );
};

export default ResumePage;
