import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";

const GitLogo = require("../assets/logos/github.ico");
const LinkedinLogo = require("../assets/logos/linkedin.ico");
const InstagramLogo = require("../assets/logos/instagram.ico");
const MailLogo = require("../assets/logos/gmail.ico");

const HomeIcon = require("../assets/icons/Home.ico");
const AboutIcon = require("../assets/icons/About.ico");
const ResumeIcon = require("../assets/icons/Resume.ico");
const PhotographyIcon = require("../assets/icons/Photography.ico");
const ContactIcon = require("../assets/icons/Contact.ico");


const LeftBar = () => {
  const [showWebList] = useState(true);
  const [showProjectsList] = useState(true);

  const WebList = () => (
    <div>
      <a href="/">
        <div className="ml-12 flex items-center hover:cursor-pointer hover:bg-opacity-80 hover:bg-[#2b2a2a] w-60">
          <img
            src={HomeIcon}
            alt="HomeIcon"
            className="w-7 mr-1  ml-5 text-yellow_vs"
          />
          <p style={{color:'#FFFFFF'}}>ome</p>
        </div>
      </a>
      <a href="/About">
        <div className="ml-12 flex items-center hover:cursor-pointer hover:bg-opacity-80 hover:bg-[#2b2a2a] w-60">
          <img
            src={AboutIcon}
            alt="AboutIcon"
            className="w-7 mr-1  ml-5 text-yellow_vs"
          />
          <p style={{color:'#FFFFFF'}}>bout</p>
        </div>
      </a>
      <a href="/Resume">
        <div className="flex whitespace-nowrap items-center hover:cursor-pointer hover:bg-opacity-80 hover:bg-[#2b2a2a] focus:bg-slate-300 ml-12 w-60">
          <img
            src={ResumeIcon}
            alt="ResumeIcon"
            className="w-7 mr-1  ml-5 text-yellow_vs"
          />
          <p style={{color:'#FFFFFF'}}>esume</p>
        </div>
      </a>
      <a href="/Photography">
        <div className="flex whitespace-nowrap items-center hover:cursor-pointer hover:bg-opacity-80 hover:bg-[#2b2a2a] ml-12 w-60">
          <img
            src={PhotographyIcon}
            alt="PhotographyIcon"
            className="w-7 mr-1  ml-5 text-yellow_vs"
          />
          <p style={{color:'#FFFFFF'}}>hotography</p>
        </div>
      </a>
      <a href="/Contact">
        <div className="flex whitespace-nowrap items-center hover:cursor-pointer hover:bg-opacity-80 hover:bg-[#2b2a2a] ml-12 w-60">
          <img
            src={ContactIcon}
            alt="ContactIcon"
            className="w-7 mr-1  ml-5 text-yellow_vs"
          />
          <p style={{color:'#FFFFFF'}}>ontact</p>
        </div>
      </a>
    </div>
  );

  return (
    <div className="text-[#a2aabc] text-lg mt-5 flex bg-[#262526] h-full">
      <div>
        {showProjectsList ? (
          <>
            <div
              className="flex items-center font-bold w-72"
            >
              <ChevronRightIcon className="w-7 mr-1  ml-5" style={{color:'#FFFFFF'}}/>
              <p style={{color:'#FFFFFF'}}>Navigation</p>
            </div>
            {showWebList ? <WebList /> : null}
          </>
        ) : null}
        <div className="absolute inset-x-0 bottom-10 ">
          <div className="flex px-6 space-x-7">
            <a href="https://github.com/TheSchroeds">
              <img
                src={GitLogo}
                alt="Git Logo"
                className="h-10 w-10 text-yellow_vs hover:cursor-pointer hover:h-12 hover:w-12"
              />
            </a>
            <a href="https://www.instagram.com/schroeds.co/">
              <img
                src={InstagramLogo}
                alt="InstagramLogo"
                className="h-10 w-10 text-yellow_vs hover:cursor-pointer hover:h-12 hover:w-12"
              />
            </a>
            <a href="https://www.linkedin.com/in/jacob-j-schroeder/">
              <img
                src={LinkedinLogo}
                alt="Linkedin Logo"
                className="h-10 w-10 text-yellow_vs hover:cursor-pointer hover:h-12 hover:w-12"
              />
            </a>
            <a href="mailto:jacobjschroeder@gmail.com">
              <img
                src={MailLogo}
                alt="Mail Logo"
                className="h-10 w-10 text-yellow_vs hover:cursor-pointer hover:h-12 hover:w-12"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
