import React, { useState } from "react";
import LeftBar from "./LeftBar";
import MobileMenu from "./MobileMenu";

const logo = require("../assets/images/homepage.png")

const Home = () => {
  const [showPage] = useState("home");

  return (
    <div id="home" className="App flex h-max bg-[#1e1e1e]">
      <div className="bg-[#262526] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div style={{position:'fixed',top:'0', left:'0'}} className="bg-[#1e1e1e] lg:pl-72">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
      <div className="bg-[#1e1e1e] lg:pl-72 lg:block hidden">
        {showPage==="home" ? (
          <>
            <img src={logo} alt='Site Logo' style={{}}/>
          </>
        )  : null}
      </div>
      <div className="lg:hidden">
        {showPage==="home" ? (
          <>
            <img src={logo} alt='Site Logo' style={{marginTop:'25%'}}/>
          </>
        )  : null}
      </div>
    </div>
  );
};

export default Home;
