import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard({ToPage, PageTitle, Content, ImagePath}) {
  return (
    <Card sx={{ maxWidth: 300}} style={{background:"#2e2e2e"}}>
      <CardActionArea href={ToPage}>
        <CardMedia
          component="img"
          height="140"
          src={ImagePath}
          alt={PageTitle}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color="#18849c">
           {PageTitle}
          </Typography>
          <Typography variant="body2" color="#FFFFFF">
            {Content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
